<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div v-if="isLoading && !input">
      <Loading />
    </div>
    <div v-else id="body-content-area-all" style="overflow: scroll">
      <div class="card">
        <div class="has-text-centered heading" :style="{
          backgroundColor: '#ededed',
          marginBottom: '0px'
        }">
          <h1 class="is-size-6" style="padding: 10px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
        </div>
        <div id="pagelayout" :style="{
          padding: activeLayout === 'administrative' ? '50px' : '25px',
          paddingTop: activeLayout === 'administrative' ? '0px' : '0px',
        }">
        <form @submit.prevent="handleSubmitLocal()">
          <div :class="['columns', activeLayout === 'administrative' ? 'is-centered' : '']">
            <div :class="['column is-full-tablet', activeLayout === 'administrative' ? 'is-three-fifths-desktop' : 'is-two-thirds-desktop']" :style="{
              marginLeft: activeLayout === 'administrative' ? (isIE ? '30%' : '32%') : '',
              marginRight: activeLayout === 'administrative' ? (isIE ? '50%' : 'auto') : '0!important',
              paddingTop: activeLayout !== 'administrative' ? '0!important' : ''
            }">
                <div class="custom-container">
                  <div class="third">
                    <div class="field is-grouped" style="padding-right: .5rem; width: 100%;">
                      <div class="control" style="width:49%;">
                        <label class="label is-size-7">First Name</label>
                        <input class="input" type="text" name="firstName" v-model="input.firstName" />
                      </div>
                      <div class="control" style="width: 49%">
                        <label class="label is-size-7">Last Name</label>
                        <input style="max-width: 302px!important;" class="input" type="text" name="lastName" v-model="input.lastName" />
                      </div>
                    </div>

                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">Email</label>
                        <input class="input" type="text" name="email" v-model="input.email" />
                      </div>
                    </div>

                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">Title</label>
                        <input class="input" type="text" name="title" v-model="input.title" />
                      </div>
                    </div>

                    <div v-if="clientSession.role == 'Team Member'" class="field is-grouped">
                      <label class="label is-size-7">Bio</label>
                      <div class="control">
                        <textarea class="textarea" v-model="input.bio"></textarea>
                      </div>
                    </div>

                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">Phone</label>
                        <input class="input" type="text" name="phone" v-model="input.phoneNumber" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row field is-grouped is-pulled-left" style="width: 100%">
                  <p class="control">
                    <button type="button" class="button is-light" @click="$refs.passwordConfirm.open()">Update 2FA Preferences</button>
                  </p>
                </div>

                <Modal @close="clearPasswordInputs()" ref="passwordConfirm">
                  <template v-slot:header>Confirm your password</template>
                  <template v-slot:body>
                    <div style="margin: 1rem 0rem;">
                      Please enter your password to continue
                      <input :class="['input', passwordErrors ? 'is-danger' : '']" type="password" v-model="password">
                    </div>
                  </template>
                  <template v-slot:footer>
                    <div class="modal-footer">
                      <button :class="['button is-accent', isPasswordConfirming ? 'saving' : '']" ref="saveButton" type="button" @click="confirmPassword" :disabled="isPasswordConfirming">
                        {{ isPasswordConfirming ? 'Confirming' : 'Confirm' }}
                        <spin-loader v-if="isPasswordConfirming" />
                      </button>
                    </div>
                  </template>
                </Modal>

                <div class="custom-container">
                  <div class="third">
                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">Old Password</label>
                        <input class="input" type="password" name="phone" v-model="oldPassword" />
                      </div>
                    </div>
                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">New Password</label>
                        <input class="input" type="password" name="phone" v-model="newPassword" />
                      </div>
                    </div>
                    <div class="field is-grouped" style="padding-right: .5rem;">
                      <div class="control" style="width:100%;">
                        <label class="label is-size-7">Verify New Password</label>
                        <input class="input" type="password" name="phone" v-model="newPassword2" />
                      </div>
                    </div>
                  </div>
                  <div class="twothird">
                    <div class="is-size-7 is-pulled-left">
                      <div v-for="item in passwordValidation" :key="item.msg + item.isValid" style="width: 100%!important">
                        <i :class="item.isValid ? 'far fa-check-circle has-text-accent': 'fas fa-times has-text-danger'"></i>: {{ item.msg }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row field is-grouped is-pulled-left" style="width: 100%">
                  <p class="control">
                    <a class="button is-light" @click="resetForm();$router.go(-1)">Cancel</a>
                  </p>
                  <p class="control">
                    <button type="submit" class="button is-accent has-text-white">Save</button>
                  </p>
                </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { activeSite, defaultPage, activeLayout } from '../vuex-actions'
import { bus } from '../main'
import Modal from '../components/partials/Modal.vue'

// add display name
// hide bio when user is of a certian type
import Loading from './Loading'
let pageName = 'Profile'

export default {
  components: {
    Loading,
    Modal
  },
  data () {
    return {
      isPasswordConfirming: false,
      input: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        title: '',
        bio: '',
        institutionId: 0
      },
      siteSettings: {},
      password: null,
      passwordErrors: false,
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      inputDefault: {},
      isLoading: true,
      institution: null,
      pageheading: pageName,
      isIE: !!window.MSInputMethodContext && !!document.documentMode
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    ...mapGetters([defaultPage]),
    ...mapGetters([activeLayout]),
    pageTitle () {
      if (this.institution) {
        return ('Profile - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      } else {
        // console.log(this.activeSite)
        return ('Profile - ' + this.activeSite.displayName)
      }
    },
    passwordValidation () {
      return this.validatePassword(
        this.newPassword,
        this.newPassword2,
        this.siteSettings
      )
    }
  },
  async created () {
    await this.getSiteSettings()
    await this.getUser()
  },
  async mounted () {
    if (['Staff', 'Manager'].includes(this.clientSession.userRole)) {
      await this.GetInstitution()
    }
    this.$nextTick(() => {
      const contentArea = document.querySelector('#content-area')
      if (contentArea) {
        contentArea.style.backgroundColor = '#d9d9d9'
      }
    })
  },
  beforeDestroy: () => {
    const contentArea = document.querySelector('#content-area')
    if (contentArea) {
      contentArea.style.backgroundColor = ''
    }
  },
  methods: {
    async confirmPassword () {
      this.isPasswordConfirming = true

      try {
        let response = await this.axios.post('/user/confirm-password', {
          password: this.password
        })

        if (response.status >= 200 && response.status < 300) {
          if (response.data) {
            this.$router.push({ name: '2FASetup', params: { passwordWasValidated: true } })
          } else {
            this.passwordErrors = true
          }
        } else {
          this.passwordErrors = true
        }
      } catch (error) {
        this.passwordErrors = true
      } finally {
        this.isPasswordConfirming = false
      }
    },
    async clearPasswordInputs () {

    },
    async GetInstitution () {
      let response = await this.axios.get(`/institutions/${this.clientSession.institutionId}/`)

      if (response.status >= 200 && response.status < 300) {
        this.institution = response.data
      }
    },
    async getUser () {
      let response = await this.axios.get(`/users/${this.clientSession.userId}?request_time=${new Date().getTime()}`)

      if (response.status >= 200 && response.status < 300) {
        this.input = response.data
      }

      this.isLoading = false
    },
    async changePassword () {
      try {
        let route = `/user/change-password`
        let payload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        }
        let response = await this.axios.post(route, payload)

        try {
          response = JSON.parse(response)
        } catch (error) {
          // do nothing
        }

        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
          this.handleSubmit(`/users/${this.clientSession.userId}`, null, true)
        } else {
          this.errorToast(response.response.data.message)
        }
      } catch (err) {
        this.errorToast(err)
        // this.handleApiErr(err)
      } finally {
        this.oldPassword = ''
        this.newPassword = ''
        this.newPassword2 = ''
      }
    },
    async handleSubmitLocal () {
      if (this.newPassword && this.oldPassword) {
        if (this.passwordValidation.some(x => !x.isValid)) {
          this.errorToast("Password doesn't meet requirements")
          return
        }
        await this.changePassword()
        return
      }

      this.input.institutionId = this.clientSession.institutionId

      if (typeof this.input.id === 'number' && this.input.id > 0) {
        await this.handleSubmit(`/users/${this.input.id}`, null, true)
      } else {
        await this.handleSubmit('/users/', null, false)
      }

      bus.$emit('updated-user', this.input)

      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

#pagelayout {
  width: 100%;
}

#body-content-area-all {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: hidden;
}
.third {
  width:50%;
  padding-bottom: 25px;
}

.twothird {
  width: 50%;
  padding-top: 25px;
  padding-left: 25px;
}

.custom-container {
  padding: 0px!important;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#app-body-area[activelayout="osr-facing"] #body-content-area-all, #app-body-area[activelayout="administrative"] #body-content-area-all, #app-body-area[activelayout="client-facing"] #body-content-area-all {
  margin: 0!important;
  width: 100%;
  padding: 1rem;
  height: calc(100vh - 170px);
}

.card {
  height: 100%;
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .third {
    width: 50%!important;
  }

  .twothird {
    width: 50%!important;
  }
}

@media (min-width: 751px) and (max-width: 1201px) {
  .third {
    width: 40%!important;
  }

  .twothird {
    width: 60%!important;
  }
}

@media (max-width: 750px) {
  .third {
    width: 100%!important;
  }

  .twothird {
    width: 100%!important;
    padding-left: 0!important;
    padding-top: 10px;
    padding-bottom: 25px;
  }
}
</style>
